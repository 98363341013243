<template>
  <div class="bg-gray-50 min-h-screen">
    <header-component></header-component>
    <div class="schedule-page p-6 bg-gray-50">
      <h1 class="text-3xl font-bold text-orange-500 mb-6">スケジュール</h1>

      <!-- タブ選択 -->
      <div class="flex justify-between m-4">
        <button 
          @click="selectedTab = 'future'"
          :class="{'bg-orange-500 text-white': selectedTab === 'future', 'bg-gray-200': selectedTab !== 'future'}"
          class="px-4 py-2 rounded-l-md focus:outline-none flex-grow"
        >
          今後のスケジュール
        </button>
        <button 
          @click="selectedTab = 'past'"
          :class="{'bg-orange-500 text-white': selectedTab === 'past', 'bg-gray-200': selectedTab !== 'past'}"
          class="px-4 py-2 rounded-r-md focus:outline-none flex-grow"
        >
          過去の履歴
        </button>
    </div>
      <!-- ローディング -->
      <div v-if="loading" class="text-center">
        <span class="loader">読み込み中...</span>
      </div>

      <!-- 終了前のクラス表示 -->
      <div v-else-if="selectedTab === 'future'">
        <div v-if="groupedFutureClasses && Object.keys(groupedFutureClasses).length > 0">
          <div v-for="(classes, date) in groupedFutureClasses" :key="date" class="mb-6">
            <div class="text-left">
              <h2 class="text-left text-2xl font-bold text-gray-700 mb-4">{{ date }}</h2>
            </div>           
             <ul class="space-y-4">
              <li v-for="classItem in classes" :key="classItem.id" class="p-4 border rounded-md shadow-sm bg-white">
                <div class="flex justify-between items-center">
                  <div>
                    <p class="text-lg font-semibold">{{ classItem.class }} - {{ classItem.startAtFormattedTime }}</p>
                    <p class="text-sm text-gray-500">{{ classItem.place }}</p>
                  </div>
                  <button
                    @click="cancelClass(classItem)"
                    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    キャンセル
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p class="text-gray-500">現在、予約はありません。</p>
        </div>
      </div>

      <!-- 終了後のクラス表示 -->
      <div v-else-if="selectedTab === 'past'">
        <div v-if="groupedPastClasses && Object.keys(groupedPastClasses).length > 0">
          <div v-for="(classes, date) in groupedPastClasses" :key="date" class="mb-6">
            <div class="text-left">
              <h2 class="text-left text-2xl font-bold text-gray-700 mb-4">{{ date }}</h2>
            </div>            
            <ul class="space-y-4">
              <li v-for="classItem in classes" :key="classItem.id" class="p-4 border rounded-md shadow-sm bg-white">
                <div class="flex justify-between items-center">
                  <div>
                    <!-- 横並び -->
                    <div class="flex justify-between items-center">
                      <p class="text-lg font-semibold">{{ classItem.place }}　{{ classItem.startAtFormattedTime }}~</p>
                      <p class="text-sm text-gray-500">{{ classItem.class }}</p>
                    </div>
                    
                    <div class="mt-2 bg-sky-50 p-2">
                      <h3 class="text-sm font-semibold mb-1">講師からのコメント</h3>
                      <p class="text-sm text-gray-900">{{ classItem.report }}</p>
                    </div>

                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p class="text-gray-500">終了した予約はありません。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import HeaderComponent from '../components/Header.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Schedule',
  components: {
    HeaderComponent,
  },
  data() {
    return {
      classes: [], // 初期値を空の配列に設定
      loading: true,
      selectedTab: 'future', // デフォルトで終了前のクラスを表示
      futureClasses: [],
      pastClasses: [],
      groupedFutureClasses: {}, // 日付ごとにグループ化した終了前のクラス
      groupedPastClasses: {}, // 日付ごとにグループ化した終了後のクラス
    };
  },
  computed: {
    ...mapGetters({
      userId: 'auth/userId' // VuexストアからユーザーIDを取得
    })
  },
  created() {
    this.fetchClasses();
  },
  methods: {
    async fetchClasses() {
      try {
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/getreservations', {
          params: {
            uid: this.userId,
          },
        });
        this.classes = response.data;
        const now = new Date();

        // 終了前と終了後でクラスを分ける
        console.log(this.classes);
        this.futureClasses = this.classes.filter(classItem => new Date(classItem.startAtUnix) > now);
        this.pastClasses = this.classes.filter(classItem => new Date(classItem.startAtUnix) <= now);
        console.log(this.futureClasses);
        console.log(this.pastClasses);
        // クラスを日付ごとにグループ化
        this.groupedFutureClasses = this.groupClassesByDate(this.futureClasses);
        this.groupedPastClasses = this.groupClassesByDate(this.pastClasses);
      } catch (error) {
        console.error('Error fetching classes:', error);
      } finally {
        this.loading = false;
      }
    },
    groupClassesByDate(classes) {
      const grouped = {};
      classes.forEach(classItem => {
        const date = classItem.startAtFormattedDate; // フォーマットされた日付を使用
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(classItem);
      });
      return grouped;
    },
    async cancelClass(item) {
      try {
        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/cancelreservation', {
          reservationId: item.reservationId,
        });
        alert('キャンセルが完了しました。');
        this.fetchClasses(); // キャンセル後に再度クラスを取得
      } catch (error) {
        console.error('Error cancelling class:', error);
        alert('キャンセルの処理中にエラーが発生しました。');
      }
    }
  },
};
</script>


<style scoped>
.schedule-page {
  max-width: 800px;
  margin: 0 auto;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #FF9800; /* Orange */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

button.selected-tab {
  background-color: #FF9800;
  color: white;
}

button.unselected-tab {
  background-color: #e2e8f0;
  color: black;
}
</style>
