import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Schedule from '@/views/Schedule.vue';
import store from '@/store';
import Reservation from '@/views/Reservation.vue';
import Register from '@/views/Register.vue';
import UserSettings from '@/views/UserSetting.vue';
import EventEntry from '@/views/EventEntry.vue';
import FreeTrial from '@/views/FreeTrial.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule,
    meta: { requiresAuth: true }
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: Reservation,
    meta: { requiresAuth: true }
  },
  {
    path: '/event',
    name: 'EventEntry',
    component: EventEntry,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'UserSettings',
    component: UserSettings,
    meta: { requiresAuth: true }
  },
  {
    path: '/free-trial',
    name: 'FreeTrial',
    component: FreeTrial,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isLoggedIn']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
