<template>
  <footer class="footer">
    <p> 2024 NPO法人クリッパー</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.footer {
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  color: #6c757d;
}
</style>
