<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
        新規会員登録
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form @submit.prevent="register">

          <div class="space-y-6">

            <div>
              <label for="studentName" class="block text-sm font-medium text-gray-700">名前</label>
              <div class="mt-1">
                <input v-model="studentName" id="studentName" name="studentName" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="guardianName" class="block text-sm font-medium text-gray-700">保護者の名前</label>
              <div class="mt-1">
                <input v-model="guardianName" id="guardianName" name="guardianName" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">メールアドレス</label>
              <div class="mt-1">
                <input v-model="email" id="email" name="email" type="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">パスワード</label>
              <div class="mt-1">
                <input v-model="password" id="password" name="password" type="password" required minlength="6" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="phoneNumber" class="block text-sm font-medium text-gray-700">電話番号</label>
              <div class="mt-1">
                <input v-model="phoneNumber" @input="validatePhoneNumber" id="phoneNumber" name="phoneNumber" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
              <p v-if="phoneNumberError" class="text-red-500 text-xs mt-1">{{ phoneNumberError }}</p>
            </div>

            <div>
              <label for="birthdate" class="block text-sm font-medium text-gray-700">生年月日</label>
              <div class="mt-1">
                <input v-model="birthdate" id="birthdate" name="birthdate" type="date" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="postalCode" class="block text-sm font-medium text-gray-700">郵便番号</label>
              <div class="mt-1">
                <input v-model="postalCode" @blur="fetchAddress" @input="validatePostalCode" id="postalCode" name="postalCode" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
              <p v-if="postalCodeError" class="text-red-500 text-xs mt-1">{{ postalCodeError }}</p>
            </div>

            <div>
              <label for="prefecture" class="block text-sm font-medium text-gray-700">都道府県</label>
              <div class="mt-1">
                <input v-model="prefecture" id="prefecture" name="prefecture" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="city" class="block text-sm font-medium text-gray-700">市区町村</label>
              <div class="mt-1">
                <input v-model="city" id="city" name="city" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>

            <div>
              <label for="address" class="block text-sm font-medium text-gray-700">住所</label>
              <div class="mt-1">
                <input v-model="address" id="address" name="address" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"/>
              </div>
            </div>
            <div>
              <div class="mt-1 flex">
                <input type="checkbox" required class="appearance mr-2"/>
                <label for="address" class="block text-sm font-medium text-gray-700">会員規約,プライバシーポリシーに同意します</label>
              </div>
            </div>

            <div>
              <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                会員登録
              </button>
            </div>
            <div v-if="errorMessage" class="mt-4 text-red-500 text-sm">
              {{ errorMessage }}
            </div>


          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'Register',
  data() {
    return {
      studentName: '',
      guardianName: '',
      email: '',
      password: '',
      phoneNumber: '',
      birthdate: '',
      postalCode: '',
      prefecture: '',
      city: '',
      address: '',
      postalCodeError: '',
      phoneNumberError: '',
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions(['login']),
    validatePostalCode() {
      const postalCodePattern = /^[0-9]{7}$/;
      if (!postalCodePattern.test(this.postalCode)) {
        this.postalCodeError = '郵便番号はハイフンなしで7桁の数字である必要があります。';
      } else {
        this.postalCodeError = '';
      }
    },
    validatePhoneNumber() {
      const phoneNumberPattern = /^0\d{9,10}$/;
      if (!phoneNumberPattern.test(this.phoneNumber)) {
        this.phoneNumberError = '電話番号は0から始まり、10〜11桁の数字である必要があります。';
      } else {
        this.phoneNumberError = '';
      }
    },
    async fetchAddress() {
      if (this.postalCode.length === 7 && !this.postalCodeError) {
        const upper = this.postalCode.slice(0, 3);
        const lower = this.postalCode.slice(3);
        const apiUrl = `https://madefor.github.io/postal-code-api/api/v1/${upper}/${lower}.json`;
        try {
          const response = await axios.get(apiUrl);
          const data = response.data;
          if (data.code === this.postalCode) {
            this.prefecture = data.data[0].ja.prefecture;
            this.city = data.data[0].ja.address1 + data.data[0].ja.address2;
          } else {
            alert('住所が見つかりませんでした。');
          }
        } catch (error) {
          console.error('住所取得エラー:', error);
          alert('住所の取得に失敗しました。');
        }
      }
    },
    async register() {
      if (this.password.length < 6) {
        alert('パスワードは6文字以上にしてください');
        return;
      }
      if (this.postalCodeError || this.phoneNumberError) {
        alert('入力にエラーがあります。修正してください。');
        return;
      }
      try {
        const response = await axios.post(process.env.VUE_APP_BASE_URL+'/createuser', {
          studentName: this.studentName,
          guardianName: this.guardianName,
          email: this.email,
          password: this.password,
          phonenumber: this.phoneNumber,
          birthdate: this.birthdate,
          postalCode: this.postalCode,
          prefecture: this.prefecture,
          city: this.city,
          address: this.address,
        });
        console.log('ユーザー登録成功:', response.data);
        alert('ユーザー登録が完了しました');
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
        });
        
        const scheduleId = this.$route.query.scheduleId;
        if (scheduleId) {
          await this.makeReservation(scheduleId);
        } else {
          const redirect = this.$route.query.redirect || '/';
          this.$router.push(redirect);
        }

      } catch (error) {
        console.error('ユーザー登録エラー:', error);
        alert('ユーザー登録に失敗しました');
      }       
    },
    async makeReservation(scheduleId) {
      try {
        const result = await axios.post(process.env.VUE_APP_BASE_URL + '/doreservation', {
          studentUID: this.$store.getters['auth/userId'],
          scheduleId: scheduleId
        });

        if (result.data.status === 'success') {
          alert('予約が完了しました！');
          this.$router.push('/');
        } else {
          alert('この授業は満席です');
        }

      } catch (error) {
        console.error('Error making reservation:', error);
        alert('Failed to make reservation.');
      }
    }  
  }
};
</script>


<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
