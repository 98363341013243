<template>
  <div class="bg-gray-50 min-h-screen">
    <header-component></header-component>
    <div class="mt-4 bg-white shadow max-w-3xl mx-auto p-8">
      <h1 class="text-3xl font-bold text-orange-500 mb-6">イベント申込</h1>
      <div class="space-y-4">
        <div class="space-y-2">
          <label class="block text-sm font-medium text-gray-700">イベント 日程</label>
          <select v-model="selectedEvent" @change="updateTotal" class="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required>
            <option value="" disabled selected>イベントを選択</option>
            <option v-for="event in events" :key="event.id" :value="event">{{ event.title }}@{{ event.place }} {{ event.StartAtFormatted }}〜</option>
          </select>
        </div>

        <div v-if="selectedEvent">
          <h2 class="text-2xl font-bold text-gray-700 mb-4">{{ selectedEvent.title }}</h2>
          <p class="text-sm mb-8" v-html="selectedEvent.description"></p>
          <div class="space-y-2">
            <label class="block text-sm font-medium text-gray-700">オプション</label>
            <div v-for="option in selectedEvent.options" :key="option.priceid" class="flex items-center space-x-3">
              <input type="checkbox" :value="option" v-model="selectedOptions" @change="updateTotal" class="form-checkbox h-5 w-5 text-orange-500">
              <span>{{ option.title }}:  ¥{{ formatCurrency(option.price) }}</span>
            </div>
          </div>

          <div class="mt-6 bg-gray-100 p-4 rounded-md">
            <h3 class="text-xl font-semibold text-gray-700">お支払い金額</h3>
            <table class="w-full text-sm mt-2">
              <tbody>
                <tr>
                  <td class="text-left">内訳</td>
                  <td class="text-right"></td>
                </tr>
                <tr>
                  <td class="text-left">参加費</td>
                  <td class="text-right">¥{{ formatCurrency(selectedEvent.basic_price) }}</td>
                </tr>
                <tr v-for="option in selectedOptions" :key="option.priceid">
                  <td class="text-left">{{ option.title }}</td>
                  <td class="text-right">¥{{ formatCurrency(option.price) }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <hr class="my-2">
                  </td>
                </tr>
                <tr>
                  <td class="text-left font-bold">合計金額</td>
                  <td class="text-right font-bold">税込 ¥{{ formatCurrency(total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 注意事項 -->
          <div class="mt-6">
            <p class="text-sm text-gray-500 mb-2">※無料イベントの場合は、お支払いの登録がなくてもご参加頂けます。</p>
            <p class="text-sm text-gray-500 mb-2">※有料イベントの場合、お申込と同時にアカウントで登録済みのクレジットカードにてお支払いされます。未登録の場合や登録のカードが無効の場合は、お申込ボタンを押した後に、カード登録画面（外部リンク）に移ります。</p>
            <p class="text-sm text-gray-500 mb-2">※お申込を持って、各イベントのキャンセルポリシーに同意したものとみなします。</p>
          </div>
          <button @click="applyEvent" class="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">申込む</button>
        </div>
      </div>
    </div>

    <!-- 支払い方法を登録してからお申込ください ポップアップ -->
    <div v-if="showPaymentPopup" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">お支払い方法が未設定です</h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">お支払い方法を設定後に再度お申込ください。</p>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
            <button @click="goToPaymentSetup" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-500 text-base font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm">クレジットカードの登録へ</button>
            <button @click="showPaymentPopup = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm">閉じる</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import HeaderComponent from '../components/Header.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EventRegistration',
  components: {
    HeaderComponent,
  },
  data() {
    return {
      events: [],
      selectedEvent: null,
      selectedOptions: [],
      total: 0,
      showPaymentPopup: false,
      stripeSetupUrl:null
    };
  },
  computed: {
    ...mapGetters({
      userId: 'auth/userId' // VuexストアからユーザーIDを取得
    })
  },
  created() {
    this.fetchEvents();
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get('id');
    const status = queryParams.get('status');
    if (eventId && status === 'forced') {
      this.autoApplyEvent(eventId);
    }
  },
  methods: {
    async fetchEvents() {
      try {
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/event-list');
        this.events = response.data;
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('ja-JP').format(value);
    },
    updateTotal() {
      if (this.selectedEvent) {
        let total = parseInt(this.selectedEvent.basic_price);
        this.selectedOptions.forEach(option => {
          total += parseInt(option.price);
        });
        this.total = total;
      }
    },
    async applyEvent() {
      try {
        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/apply-event', {
          studentUID: this.userId,
          eventId: this.selectedEvent.id,
          options: this.selectedOptions.map(option => ({
            title: option.title,
            price: option.price,
            stripeid: option.stripeid,
            priceid: option.priceid
          }))
        });
        if (response.data.status === 'success') {
          alert('イベントの申込が完了しました！');
          this.$router.push('/events');
        } else if (response.data.status === 'payment_required') {
          this.stripeSetupUrl = response.data.url;
          this.showPaymentPopup = true;
        } else {
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.error('Error applying for event:', error);
        alert('イベントの申込中にエラーが発生しました。');
      }
    },
    goToPaymentSetup() {
      window.location.href = this.stripeSetupUrl;
    },
    async autoApplyEvent(eventId) {
      try {
        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/apply-event', {
          studentUID: this.userId,
          eventId: eventId,
          options: []
        });
        if (response.data.status === 'success') {
          alert('イベントの申込が完了しました！');
          this.$router.push('/events');
        } else if (response.data.status === 'payment_required') {
          this.showPaymentPopup = true;
        } else {
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.error('Error applying for event:', error);
        alert('イベントの申込中にエラーが発生しました。');
      }
    }
  }
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
