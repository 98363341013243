import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { app } from '../firebase';

const auth = getAuth(app);

export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  mutations: {
    setUser(state, user) {
      state.currentUser = user;
    },
    clearUser(state) {
      state.currentUser = null;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        commit('setUser', userCredential.user);
      } catch (error) {
        throw new Error(`Login failed: ${error.message}`);
      }
    },
    async logout({ commit }) {
      try {
        await signOut(auth);
        commit('clearUser');
      } catch (error) {
        throw new Error(`Logout failed: ${error.message}`);
      }
    },
    async initialize({ commit }) {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, user => {
          if (user) {
            commit('setUser', user);
          } else {
            commit('clearUser');
          }
          resolve();
        });
      });
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.currentUser;
    },
    currentUser(state) {
      return state.currentUser;
    },
    userId(state) {
      return state.currentUser ? state.currentUser.uid : null;
    }
  }
};
