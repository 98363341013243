<template>
  <header class="bg-white shadow-sm">
    <div class="container mx-auto flex justify-between items-center py-4 px-6">
      <div class="flex items-center">
        <img src="@/assets/images/logo.png" alt="Logo" class="h-6 mr-4">
        <div class="hidden lg:flex space-x-6">
          <router-link to="/" class="text-gray-700 hover:text-orange-500 font-semibold">ホーム</router-link>
          <router-link to="/schedule" class="text-gray-700 hover:text-orange-500 font-semibold">授業の予定</router-link>
          <router-link to="/reservation" class="text-gray-700 hover:text-orange-500 font-semibold">新規予約</router-link>
          <router-link to="/event" class="text-gray-700 hover:text-orange-500 font-semibold">イベント申込</router-link>
        </div>
      </div>
      <div class="relative hidden lg:flex items-center space-x-4 bg-gray-50">
        <div v-if="userData" class="relative shrink-0 group block">
          <div class="flex items-center cursor-pointer" @click="toggleUserMenu">
            <svg class="h-8 w-8 text-gray-300" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.62854" y="0.359985" width="15" height="15" rx="7.5" fill="white"></rect>
              <path d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z" fill="currentColor"></path>
              <path d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z" fill="currentColor"></path>
            </svg>
          </div>
          <div v-if="isUserMenuOpen" class="absolute right-0 mt-2 bg-white shadow-lg rounded-lg z-50">
            <div class="py-4 px-4">
              <h3 class="font-semibold text-gray-800">{{ userData.parentName }}</h3>
              <p class="text-gray-500 text-sm">{{ userData.email }}</p>
            </div>
            <hr class="border-gray-200 my-2">
            <ul class="py-1">
              <li>
                <router-link to="/settings" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                  ユーザー設定
                </router-link>
              </li>
              <!-- 今後メニュー項目を追加 -->
            </ul>
          </div>
        </div>
      </div>
      <div class="lg:hidden">
        <button @click="toggleMenu" type="button" class="text-gray-700 hover:text-orange-500 focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>

    <div v-if="isMenuOpen" class="lg:hidden relative bg-white z-50">
      <div class="absolute top-0 left-0 w-full mt-12 bg-white shadow-lg rounded-lg">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <router-link to="/" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-orange-500">ホーム</router-link>
          <router-link to="/schedule" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-orange-500">授業の予定</router-link>
          <router-link to="/reservation" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-orange-500">新規予約</router-link>
          <router-link to="/event" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-orange-500">イベント申込</router-link>
          <router-link to="/settings" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-orange-500">ユーザー設定</router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      isMenuOpen: false,
      isUserMenuOpen: false,
      userData: null,
    };
  },
  computed: {
    ...mapGetters({
      uid: 'auth/userId', // VuexストアからユーザーIDを取得
    })
  },
  created() {
    this.fetchUserData();
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    async fetchUserData() {
      try {
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/user', {
          params: {
            uid: this.uid
          }
        });
        this.userData = response.data.data;
      } catch (error) {
        console.error('ユーザー情報の取得に失敗しました:', error);
        alert('ユーザー情報の取得に失敗しました');
      }
    }
  }
};
</script>

<style scoped>
/* ポップアップの影を全方向に追加 */
.shadow-lg {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px -1px 3px rgba(0, 0, 0, 0.12);
}

/* メニューリストとユーザー情報の間に適度な間隔とボーダーを追加 */
hr {
  border-top: 1px solid #e5e7eb;
  margin-top: 0;
  margin-bottom: 0;
}

.ml-3 {
  margin-left: 0.75rem; /* ユーザー情報の左マージン */
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; /* ユーザー情報の上下パディング */
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; /* ユーザー情報の左右パディング */
}
</style>
