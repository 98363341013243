import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/global.css';
import './assets/tailwind.css';
// import 'preline/dist/preline.css';  // Correct path to Preline CSS

const app = createApp(App);

store.dispatch('auth/initialize').then(() => {
  app.use(router);
  app.use(store);
  app.mount('#app');
});
