<template>
  <div class="bg-gray-50 min-h-screen">
    <header-component></header-component>
  </div>
</template>

<script>
import HeaderComponent from '../components/Header.vue';
import FooterComponent from '../components/Footer.vue';

export default {
  name: 'Home',
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style scoped>
.home {
  padding: 20px;
}
</style>
